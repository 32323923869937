<template>
    <v-container>
        <base-material-card icon="mdi-account-network" title="Usuarios" class="px-5 py-3">
            <v-row>
                <v-col cols="12">
                    <v-btn color="primary" @click="$router.push({name: 'UsuarioCreate'})">Nuevo</v-btn>
                    <v-text-field v-model="pagination.search" append-icon="mdi-magnify" label="Búsqueda" single-line hide-details @input="pagination.page = 1; load()" clearable></v-text-field>
                </v-col>
                <v-col cols="12">
                    <v-data-table :headers="headers" :items="usuarios" item-key="id" hide-default-footer disable-pagination class="elevation-1">
                        <template v-slot:item.enabled="{item}">
                            {{item.enabled ? 'Activo' : 'Inactivo'}}
                        </template>
                        <template v-slot:item.acciones="{item}">
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn v-if="item.role.nombre == 'Vendedor'" v-on="on" fab icon x-small @click="$router.push({name: 'VendedorEdit', params: {id: item.vendedor.id}})">
                                        <v-icon>far fa-edit</v-icon>
                                    </v-btn>
                                    <v-btn v-else v-on="on" fab icon x-small @click="$router.push({name: 'UsuarioEdit', params: {id: item.id}})">
                                        <v-icon>far fa-edit</v-icon>
                                    </v-btn>
                                </template>
                                <span>Editar</span>
                            </v-tooltip>
                        </template>
                    </v-data-table>
                </v-col>
                <v-col col="12">
                    <v-pagination v-model="pagination.page" class="my-4" :length="pagination.last_page" @input="load" :total-visible="10" color="primary"></v-pagination>
                </v-col>
            </v-row>
        </base-material-card>
    </v-container>
</template>
<script>
    export default {
        data: () => ({
            headers: [
                { text: 'Nombre', value: 'name', sortable: false },
                { text: 'Correo electrónico', value: 'email', sortable: false },
                { text: 'Role', value: 'role.nombre', sortable: false },
                { text: 'Status', value: 'enabled', sortable: false },
                { text: 'Acciones', value: 'acciones', sortable: false },
            ],
            usuarios: [],
            pagination: {
                perPage: 10,
                last_page: 1,
                page: 1,
                search: null
            }
        }),
        mounted() {
            this.load()
        },
        methods: {
            load() {
                this.$http.get('/usuarios/', {
                    params: this.pagination
                }).then(response => {
                    this.usuarios = response.data.data
                    this.pagination.last_page = response.data.last_page
                    this.pagination.page = response.data.current_page
                })
            }
        }
    }
</script>